import { useLazyQuery } from '@apollo/client';
import React, { createContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_SESSION } from '../graphql/services/Session';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const initialRender = useRef(true);
    const activityTimeoutRef = useRef(null);

    const inactivityTime = 3600000; // 1 hour in milliseconds

    const [authState, setAuthState] = useState({
        token: localStorage.getItem('token'),
        user: null,
    });

    const [getUserSession, { loading, error, data }] = useLazyQuery(GET_SESSION, {
        onCompleted: (data) => {
            setAuthState(prevState => ({
                ...prevState,
                user: data.getSessionUser.result,
            }));
        },
        onError: (error) => {
            navigate('/');
            console.log('error: ', error);
        },
    });

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            const token = localStorage.getItem('token');
            if (token) {
                getUserSession();
            }
        }

        const handleActivity = () => {
            resetTimer();
        };

        const resetTimer = () => {
            if (activityTimeoutRef.current) {
                clearTimeout(activityTimeoutRef.current);
            }
            activityTimeoutRef.current = setTimeout(logout, inactivityTime);
        };

        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);

        resetTimer();

        return () => {
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
            if (activityTimeoutRef.current) {
                clearTimeout(activityTimeoutRef.current);
            }
        };
    }, [getUserSession]);

    const login = (token) => {
        localStorage.setItem('token', token);
        setAuthState((prevState) => ({
            ...prevState,
            token,
        }));
        getUserSession().then(() => {
            navigate('/beranda');
        });
    };

    const logout = () => {
        setAuthState({
            token: null,
            user: null,
        });
        localStorage.removeItem('token');
        navigate('/');
    };

    return (
        <AuthContext.Provider value={{ authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };
