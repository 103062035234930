import React from 'react'
import { Grid } from '@mui/material'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import MenuHome from '../components/MenuHome'

export const Home = ({ user, menu, onClick }) => {
    return (
        <div>
            <Navbar user={user}/>
            <div
                style={{
                    marginTop: '114px',
                    marginBottom: '114px',
                }}
            >
                <Grid item xs={7}>
                    <Grid container spacing={5} >
                        {
                            menu !== undefined ? menu.submenus.map((i) => {
                                if(i.level_id === "2") {
                                    return (
                                        <Grid item xs={12} sm={6} md={6} key={i.name} sx={{display: 'flex', justifyContent: 'center'}}>
                                            <MenuHome data={i} route={i.name} onClick={onClick} allMenus={menu}/>
                                        </Grid>
                                    )
                                }
                            }) : []
                        }
                    </Grid>
                </Grid>
            </div>
            
            <Footer/>
        </div>
    )
}