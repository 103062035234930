import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthWrapper";
import { useQuery } from "@apollo/client";
// import { GET_MENU } from '../graphql/services/Menu'
import CircularProgress from "@mui/material/CircularProgress";
import { Home } from "../pages/Home";
import { useGetMenu } from "../graphql/services/Menu";

export const HomeContainer = () => {
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data, loading, error } = useGetMenu();

  useEffect(() => {
    if (!authState.token || !authState.user) {
      navigate("/");
    }
  }, [authState, navigate]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "30%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    navigate("/");
  }

  const allMenu = data ? data.getMenuByUser.result[0] : [];

  return <Home user={authState.user} menu={allMenu} />;
};
