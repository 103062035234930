import React from 'react'
import { Button, Grid } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'

export const ButtonCustom = ({ data, status, onClick, loading, disable, size }) => {
    const handleClick = async (event) => {
        onClick(event)
    }
    
    return (
        <>
            <Button
                onClick={handleClick}
                variant="contained"
                disabled={loading || disable}
                disableElevation
                sx = {{
                    background: status === 'login'? 'linear-gradient(to right, #FB8D75, #7054E4)' : (status === 'cancel' ? '#FF0E0E' : '#7054E4'),
                    width: size !== undefined ? size : (status === 'add' ? '158px' :  (status === 'add-small' ? '116px' : '136px')),
                    height: status === 'add-small' || status === 'add' || status === 'download' ? '29px' :'36px',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 700,
                    color: 'white',
                    borderRadius: status === 'add-small' || status === 'add' || status === 'download' ? '8px' : '18px',
                    '&:hover': {
                        background: status === 'login'? 'linear-gradient(to right, #D97764, #634BB2)' :  (status === 'cancel' ? '#CD0E0E' : '#5842B1'),
                    }
                }}
            >     
                <Grid container
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                    sx={{
                        padding: '0 0 2px 2px '
                    }}
                >
                
                    {
                        status === 'add' || status === 'add-small' ? 
                        <>
                            <Grid item xs={2}
                                justifyContent="start"
                                display="flex"
                                alignItems="start"
                            >
                                +
                            </Grid>
                            <Grid item xs={10} sx={{ fontSize: '12px', fontWeight: 600 }}>
                                {data}
                            </Grid>
                        </>
                        : (status === 'download' ? <>
                            <Grid item xs={2}
                                justifyContent="start"
                                display="flex"
                                alignItems="start"
                            >
                                <img src={require(`../assets/icon/downloads.png`)} style={{ width: 15 }}/>
                            </Grid>
                            <Grid item xs={10} sx={{ fontSize: '12px', fontWeight: 600 }}>
                                {data}
                            </Grid>
                        </> : (loading ? <CircularProgress size="1.5rem" /> : data))
                    }
                </Grid>
            </Button>
        </>
        
    )
}

export const ButtonAction = ({type, onClick}) => {
    const handleClick = async (event) => {
        onClick(event)
    }

    const getIcon = (type) => {
        switch (type) {
            case 'delete':
                return <DeleteIcon sx={{height: 23}}/>
            case 'edit':
                return <EditIcon  sx={{height: 20}}/>
            case 'view':
                return <VisibilityIcon  sx={{height: 20}}/>
            default:
                return null
        }
    }

    const getColor = (type) => {
        switch (type) {
            case 'delete':
                return '#FF0000'
            case 'edit':
                return '#28BEFF'
            case 'view':
                return '#64CCC5'
            default:
                return '#000'
        }
    }
    
    return (
        <>
            <Button
                onClick={handleClick}
                variant="contained"
                sx = {{
                    backgroundColor: getColor(type),
                    maxWidth: '25px', 
                    maxHeight: '25px', 
                    minWidth: '25px', 
                    minHeight: '25px',
                    textAlign: 'center',
                    color: 'white',
                    borderRadius: '5px',
                    '&:hover': {
                        backgroundColor: getColor(type)
                    }
                }}
            >     
                {getIcon(type)}
            </Button>
        </>
        
    )
}