import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'

export const GET_SESSION = gql`
    query GetSessionUser {
        getSessionUser {
            statusCode
            message
            result {
                username
                password
                email
                nama
                role_id
                supplier_id
                supplier_name
                branch_id
                branch_name
                branch_address
                is_active
            }
        }
    }
`

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($newPassword: String!, $oldPassword: String!) {
        changePassword(newpassword: $newPassword, oldpassword: $oldPassword) {
            statusCode
            message
        }
    }
`

export const useUserSession = () => {
    const [fetchUserSession, { data, loading, error }] = useLazyQuery(GET_SESSION)
    return { fetchUserSession, data, loading, error }
}

export const useChangePassword = () => {
    return useMutation(CHANGE_PASSWORD)
}